import {
  type NWTEvent,
  type PageViewTrackingEvent,
  useTracking,
} from '@xing-com/crate-communication-tracking';

export type TrackParams = {
  page?: string;
  PropTrackAction?: string;
  hashedUserId?: string;
  signupChannel?: string;
  EventRegistrationUnconfirmed?: number;
};

type LoginTrackingType = {
  trackPageView: (params: TrackParams) => void;
};

const useLoginTracking = (): LoginTrackingType => {
  const { track: trackAdobePageView } = useTracking<PageViewTrackingEvent>();
  const { track: trackNwt } = useTracking<NWTEvent>();

  const trackPageView = ({
    page,
    PropTrackAction,
    hashedUserId,
    signupChannel,
    EventRegistrationUnconfirmed,
  }: TrackParams): void => {
    const contextProps: Record<string, unknown> = {};
    const additionalNWTProps: Record<string, unknown> = {};

    if (PropTrackAction) {
      contextProps.PropTrackAction = PropTrackAction;
    }
    if (EventRegistrationUnconfirmed) {
      contextProps.EventRegistrationUnconfirmed = EventRegistrationUnconfirmed;
    }
    if (hashedUserId) {
      contextProps.PropHashedUserId = hashedUserId;
      additionalNWTProps.userId = hashedUserId;
    }
    if (signupChannel) {
      contextProps.PropRegistrationBackendChannel = signupChannel;
    }

    trackAdobePageView({
      type: 'pageview',
      channel: 'wbm/Welcome',
      page: `wbm/Welcome/${page}`,
      ...contextProps,
    });

    trackNwt({
      type: 'nwt',
      event: 'viewed_screen',
      eventSchema: 'basic',
      sentBy: 'welcome',
      page: `welcome/${page}`,
      entryPoint: signupChannel ? `signup_channel_${signupChannel}` : undefined,
      ...additionalNWTProps,
    });
  };

  return {
    trackPageView,
  };
};

export { useLoginTracking };
