// This file is autogenerated. Please don’t change it manually.
// See packages/xds/designkit/src/handle-variables.ts to make changes.
export const spaceXXS = '2px';
export const spaceXS = '4px';
export const spaceS = '8px';
export const spaceM = '12px';
export const spaceL = '16px';
export const spaceXL = '24px';
export const spaceXXL = '32px';
export const space3XL = '48px';
export const space4XL = '68px';
export const space5XL = '92px';
export const space6XL = '122px';
export const scale000 = '0';
export const scale005 = '1px';
export const scale010 = '2px';
export const scale020 = '4px';
export const scale030 = '6px';
export const scale040 = '8px';
export const scale050 = '10px';
export const scale060 = '12px';
export const scale070 = '14px';
export const scale080 = '16px';
export const scale090 = '18px';
export const scale100 = '20px';
export const scale110 = '24px';
export const scale120 = '28px';
export const scale130 = '32px';
export const scale140 = '36px';
export const scale150 = '42px';
export const scale160 = '48px';
export const scale170 = '54px';
export const scale180 = '60px';
export const scale190 = '68px';
export const scale200 = '76px';
export const scale210 = '84px';
export const scale220 = '92px';
export const scale230 = '102px';
export const scale240 = '112px';
export const scale250 = '122px';
export const scale260 = '132px';
export const scale270 = '144px';
export const scale280 = '156px';
export const scale290 = '168px';
export const scale300 = '180px';
export const scale310 = '194px';
export const scale320 = '208px';
export const scale330 = '222px';
export const scale340 = '236px';
export const scale350 = '252px';
export const scale360 = '268px';
export const scale370 = '284px';
export const scale380 = '300px';
export const scale390 = '318px';
export const scale400 = '336px';
export const scale410 = '354px';
export const scale420 = '372px';
export const scale430 = '392px';
export const scale440 = '412px';
export const scale450 = '432px';
export const motionTimeXXS = '70ms';
export const motionTimeNumberXXS = '70';
export const motionTimeXS = '110ms';
export const motionTimeNumberXS = '110';
export const motionTimeS = '150ms';
export const motionTimeNumberS = '150';
export const motionTimeM = '240ms';
export const motionTimeNumberM = '240';
export const motionTimeL = '400ms';
export const motionTimeNumberL = '400';
export const motionTimeXL = '700ms';
export const motionTimeNumberXL = '700';
export const motionTimeXXL = '1000ms';
export const motionTimeNumberXXL = '1000';
export const motionEasingEnter = 'cubic-bezier(0, 0, 0.2, 1)';
export const motionEasingLeave = 'cubic-bezier(0.4, 0, 1, 1)';
export const motionEasingStandard = 'cubic-bezier(0.4, 0, 0.2, 1)';
export const dropShadowS = '0 2px 14px 2px rgba(33, 42, 54, 0.08)';
export const dropShadowM = '0 4px 16px 3px rgba(33, 42, 54, 0.13)';
export const cornerRadiusS = '2px';
export const cornerRadiusM = '4px';
export const cornerRadiusL = '8px';
export const cornerRadiusXL = '16px';
export const cornerRadiusXXL = '24px';
export const cornerRadius3XL = '32px';
export const cornerRadius4XL = '48px';
export const xdlColorBackground = 'var(--xdlColorBackground)';
export const xdlColorBackgroundDark = 'var(--xdlColorBackgroundDark)';
export const xdlColorBackgroundLight = 'var(--xdlColorBackgroundLight)';
export const xdlColorBackgroundSecondary = 'var(--xdlColorBackgroundSecondary)';
export const xdlColorBackgroundTertiary = 'var(--xdlColorBackgroundTertiary)';
export const xdlColorBeta = 'var(--xdlColorBeta)';
export const xdlColorBorder = 'var(--xdlColorBorder)';
export const xdlColorBorderSoft = 'var(--xdlColorBorderSoft)';
export const xdlColorBorderStrong = 'var(--xdlColorBorderStrong)';
export const xdlColorButtonSecondaryHover =
  'var(--xdlColorButtonSecondaryHover)';
export const xdlColorButtonStrong = 'var(--xdlColorButtonStrong)';
export const xdlColorButtonStrongHover = 'var(--xdlColorButtonStrongHover)';
export const xdlColorCardHero01 = 'var(--xdlColorCardHero01)';
export const xdlColorCardHero02 = 'var(--xdlColorCardHero02)';
export const xdlColorCardHero03 = 'var(--xdlColorCardHero03)';
export const xdlColorCardHero04 = 'var(--xdlColorCardHero04)';
export const xdlColorCardHero05 = 'var(--xdlColorCardHero05)';
export const xdlColorCardHero06 = 'var(--xdlColorCardHero06)';
export const xdlColorCardHero07 = 'var(--xdlColorCardHero07)';
export const xdlColorCardHero08 = 'var(--xdlColorCardHero08)';
export const xdlColorCardInspireText01 = 'var(--xdlColorCardInspireText01)';
export const xdlColorCardInspireText02 = 'var(--xdlColorCardInspireText02)';
export const xdlColorCardInspireText03 = 'var(--xdlColorCardInspireText03)';
export const xdlColorCardInspire01 = 'var(--xdlColorCardInspire01)';
export const xdlColorCardInspire02 = 'var(--xdlColorCardInspire02)';
export const xdlColorCardInspire03 = 'var(--xdlColorCardInspire03)';
export const xdlColorCardInspire04 = 'var(--xdlColorCardInspire04)';
export const xdlColorCardInspire05 = 'var(--xdlColorCardInspire05)';
export const xdlColorCardInspire06 = 'var(--xdlColorCardInspire06)';
export const xdlColorCardInspire07 = 'var(--xdlColorCardInspire07)';
export const xdlColorCaution = 'var(--xdlColorCaution)';
export const xdlColorChart01 = 'var(--xdlColorChart01)';
export const xdlColorChart02 = 'var(--xdlColorChart02)';
export const xdlColorChart03 = 'var(--xdlColorChart03)';
export const xdlColorChart04 = 'var(--xdlColorChart04)';
export const xdlColorChart05 = 'var(--xdlColorChart05)';
export const xdlColorChart06 = 'var(--xdlColorChart06)';
export const xdlColorChart07 = 'var(--xdlColorChart07)';
export const xdlColorChart08 = 'var(--xdlColorChart08)';
export const xdlColorChart09 = 'var(--xdlColorChart09)';
export const xdlColorControlBorder = 'var(--xdlColorControlBorder)';
export const xdlColorControlBorderSecondary =
  'var(--xdlColorControlBorderSecondary)';
export const xdlColorControlBorderTertiary =
  'var(--xdlColorControlBorderTertiary)';
export const xdlColorControlFill = 'var(--xdlColorControlFill)';
export const xdlColorControlFillQuaternary =
  'var(--xdlColorControlFillQuaternary)';
export const xdlColorControlFillSecondary =
  'var(--xdlColorControlFillSecondary)';
export const xdlColorControlFillTertiary = 'var(--xdlColorControlFillTertiary)';
export const xdlColorDimming = 'var(--xdlColorDimming)';
export const xdlColorDisabled = 'var(--xdlColorDisabled)';
export const xdlColorDisabledOnColor = 'var(--xdlColorDisabledOnColor)';
export const xdlColorDisabledSoft = 'var(--xdlColorDisabledSoft)';
export const xdlColorDisabledStrong = 'var(--xdlColorDisabledStrong)';
export const xdlColorError = 'var(--xdlColorError)';
export const xdlColorErrorSoft = 'var(--xdlColorErrorSoft)';
export const xdlColorFocussed = 'var(--xdlColorFocussed)';
export const xdlColorGo = 'var(--xdlColorGo)';
export const xdlColorHighlighted = 'var(--xdlColorHighlighted)';
export const xdlColorHover = 'var(--xdlColorHover)';
export const xdlColorHoverOnColor = 'var(--xdlColorHoverOnColor)';
export const xdlColorHoverOnColorStrong = 'var(--xdlColorHoverOnColorStrong)';
export const xdlColorHoverQuaternary = 'var(--xdlColorHoverQuaternary)';
export const xdlColorHoverSecondary = 'var(--xdlColorHoverSecondary)';
export const xdlColorHoverTertiary = 'var(--xdlColorHoverTertiary)';
export const xdlColorInfo = 'var(--xdlColorInfo)';
export const xdlColorInfoSoft = 'var(--xdlColorInfoSoft)';
export const xdlColorLink = 'var(--xdlColorLink)';
export const xdlColorLinkHover = 'var(--xdlColorLinkHover)';
export const xdlColorLinkVisited = 'var(--xdlColorLinkVisited)';
export const xdlColorMarkerHighlight = 'var(--xdlColorMarkerHighlight)';
export const xdlColorMarkerHighlightText = 'var(--xdlColorMarkerHighlightText)';
export const xdlColorMarkerMatch = 'var(--xdlColorMarkerMatch)';
export const xdlColorMarkerMatchText = 'var(--xdlColorMarkerMatchText)';
export const xdlColorMarkerStrong = 'var(--xdlColorMarkerStrong)';
export const xdlColorMeHubAssessmentHighlight =
  'var(--xdlColorMeHubAssessmentHighlight)';
export const xdlColorMeHubHighlight = 'var(--xdlColorMeHubHighlight)';
export const xdlColorMenuHighlight = 'var(--xdlColorMenuHighlight)';
export const xdlColorMenuHighlightHover = 'var(--xdlColorMenuHighlightHover)';
export const xdlColorMenuHighlightPressed =
  'var(--xdlColorMenuHighlightPressed)';
export const xdlColorMenuPressed = 'var(--xdlColorMenuPressed)';
export const xdlColorMenuStrong = 'var(--xdlColorMenuStrong)';
export const xdlColorMenuStrongHover = 'var(--xdlColorMenuStrongHover)';
export const xdlColorMenuStrongPressed = 'var(--xdlColorMenuStrongPressed)';
export const xdlColorModalBorder = 'var(--xdlColorModalBorder)';
export const xdlColorNew = 'var(--xdlColorNew)';
export const xdlColorNewListItemSoft = 'var(--xdlColorNewListItemSoft)';
export const xdlColorNotification = 'var(--xdlColorNotification)';
export const xdlColorPollIndicatorPrimary =
  'var(--xdlColorPollIndicatorPrimary)';
export const xdlColorPollIndicatorSecondary =
  'var(--xdlColorPollIndicatorSecondary)';
export const xdlColorPremium = 'var(--xdlColorPremium)';
export const xdlColorPremiumSecondary = 'var(--xdlColorPremiumSecondary)';
export const xdlColorPremiumTertiary = 'var(--xdlColorPremiumTertiary)';
export const xdlColorPricing = 'var(--xdlColorPricing)';
export const xdlColorPrimaryButtonHover = 'var(--xdlColorPrimaryButtonHover)';
export const xdlColorProBusiness = 'var(--xdlColorProBusiness)';
export const xdlColorProBusinessQuaternary =
  'var(--xdlColorProBusinessQuaternary)';
export const xdlColorProBusinessSecondary =
  'var(--xdlColorProBusinessSecondary)';
export const xdlColorProBusinessTertiary = 'var(--xdlColorProBusinessTertiary)';
export const xdlColorProJobs = 'var(--xdlColorProJobs)';
export const xdlColorProJobsFill = 'var(--xdlColorProJobsFill)';
export const xdlColorProJobsSecondary = 'var(--xdlColorProJobsSecondary)';
export const xdlColorProJobsTertiary = 'var(--xdlColorProJobsTertiary)';
export const xdlColorProgressBadgeBackground01 =
  'var(--xdlColorProgressBadgeBackground01)';
export const xdlColorProgressBadgeBackground02 =
  'var(--xdlColorProgressBadgeBackground02)';
export const xdlColorProgressBadgeBackground03 =
  'var(--xdlColorProgressBadgeBackground03)';
export const xdlColorProgressBadgeBackground04 =
  'var(--xdlColorProgressBadgeBackground04)';
export const xdlColorSelected = 'var(--xdlColorSelected)';
export const xdlColorSelectedSecondary = 'var(--xdlColorSelectedSecondary)';
export const xdlColorSelectedTertiary = 'var(--xdlColorSelectedTertiary)';
export const xdlColorSkeletonPrimary = 'var(--xdlColorSkeletonPrimary)';
export const xdlColorSkeletonPrimaryAnimation =
  'var(--xdlColorSkeletonPrimaryAnimation)';
export const xdlColorSkeletonSecondary = 'var(--xdlColorSkeletonSecondary)';
export const xdlColorSkeletonSecondaryAnimation =
  'var(--xdlColorSkeletonSecondaryAnimation)';
export const xdlColorSliderTickMark = 'var(--xdlColorSliderTickMark)';
export const xdlColorStop = 'var(--xdlColorStop)';
export const xdlColorSuccess = 'var(--xdlColorSuccess)';
export const xdlColorSuccessSoft = 'var(--xdlColorSuccessSoft)';
export const xdlColorText = 'var(--xdlColorText)';
export const xdlColorTextInvert = 'var(--xdlColorTextInvert)';
export const xdlColorTextOnDark = 'var(--xdlColorTextOnDark)';
export const xdlColorTextOnLight = 'var(--xdlColorTextOnLight)';
export const xdlColorTextSecondary = 'var(--xdlColorTextSecondary)';
export const xdlColorTextSecondaryOnDark = 'var(--xdlColorTextSecondaryOnDark)';
export const xdlColorTextSecondaryOnLight =
  'var(--xdlColorTextSecondaryOnLight)';
export const xdlColorTextTertiary = 'var(--xdlColorTextTertiary)';
export const xdlColorTextTertiaryOnDark = 'var(--xdlColorTextTertiaryOnDark)';
export const xdlColorTextTertiaryOnLight = 'var(--xdlColorTextTertiaryOnLight)';
export const xdlColorUnseen = 'var(--xdlColorUnseen)';
export const xdlColorUserRoles = 'var(--xdlColorUserRoles)';
export const xdlColorXING = 'var(--xdlColorXING)';
export const xdlPaletteBerry01 = 'var(--xdlPaletteBerry01)';
export const xdlPaletteBerry02 = 'var(--xdlPaletteBerry02)';
export const xdlPaletteBerry03 = 'var(--xdlPaletteBerry03)';
export const xdlPaletteBerry04 = 'var(--xdlPaletteBerry04)';
export const xdlPaletteBerry05 = 'var(--xdlPaletteBerry05)';
export const xdlPaletteBerry06 = 'var(--xdlPaletteBerry06)';
export const xdlPaletteBerry07 = 'var(--xdlPaletteBerry07)';
export const xdlPaletteBerry08 = 'var(--xdlPaletteBerry08)';
export const xdlPaletteBerry09 = 'var(--xdlPaletteBerry09)';
export const xdlPaletteBerry10 = 'var(--xdlPaletteBerry10)';
export const xdlPaletteBerry11 = 'var(--xdlPaletteBerry11)';
export const xdlPaletteBlue01 = 'var(--xdlPaletteBlue01)';
export const xdlPaletteBlue02 = 'var(--xdlPaletteBlue02)';
export const xdlPaletteBlue03 = 'var(--xdlPaletteBlue03)';
export const xdlPaletteBlue04 = 'var(--xdlPaletteBlue04)';
export const xdlPaletteBlue05 = 'var(--xdlPaletteBlue05)';
export const xdlPaletteBlue06 = 'var(--xdlPaletteBlue06)';
export const xdlPaletteBlue07 = 'var(--xdlPaletteBlue07)';
export const xdlPaletteBlue08 = 'var(--xdlPaletteBlue08)';
export const xdlPaletteBlue09 = 'var(--xdlPaletteBlue09)';
export const xdlPaletteBlue10 = 'var(--xdlPaletteBlue10)';
export const xdlPaletteBlue11 = 'var(--xdlPaletteBlue11)';
export const xdlPaletteGrayCool01 = 'var(--xdlPaletteGrayCool01)';
export const xdlPaletteGrayCool02 = 'var(--xdlPaletteGrayCool02)';
export const xdlPaletteGrayCool03 = 'var(--xdlPaletteGrayCool03)';
export const xdlPaletteGrayCool04 = 'var(--xdlPaletteGrayCool04)';
export const xdlPaletteGrayCool05 = 'var(--xdlPaletteGrayCool05)';
export const xdlPaletteGrayCool06 = 'var(--xdlPaletteGrayCool06)';
export const xdlPaletteGrayCool07 = 'var(--xdlPaletteGrayCool07)';
export const xdlPaletteGrayCool08 = 'var(--xdlPaletteGrayCool08)';
export const xdlPaletteGrayCool09 = 'var(--xdlPaletteGrayCool09)';
export const xdlPaletteGrayCool10 = 'var(--xdlPaletteGrayCool10)';
export const xdlPaletteGrayCool11 = 'var(--xdlPaletteGrayCool11)';
export const xdlPaletteGrayPlain01 = 'var(--xdlPaletteGrayPlain01)';
export const xdlPaletteGrayPlain02 = 'var(--xdlPaletteGrayPlain02)';
export const xdlPaletteGrayPlain03 = 'var(--xdlPaletteGrayPlain03)';
export const xdlPaletteGrayPlain04 = 'var(--xdlPaletteGrayPlain04)';
export const xdlPaletteGrayPlain05 = 'var(--xdlPaletteGrayPlain05)';
export const xdlPaletteGrayPlain06 = 'var(--xdlPaletteGrayPlain06)';
export const xdlPaletteGrayPlain07 = 'var(--xdlPaletteGrayPlain07)';
export const xdlPaletteGrayPlain08 = 'var(--xdlPaletteGrayPlain08)';
export const xdlPaletteGrayPlain09 = 'var(--xdlPaletteGrayPlain09)';
export const xdlPaletteGrayPlain10 = 'var(--xdlPaletteGrayPlain10)';
export const xdlPaletteGrayPlain11 = 'var(--xdlPaletteGrayPlain11)';
export const xdlPaletteLime01 = 'var(--xdlPaletteLime01)';
export const xdlPaletteLime02 = 'var(--xdlPaletteLime02)';
export const xdlPaletteLime03 = 'var(--xdlPaletteLime03)';
export const xdlPaletteLime04 = 'var(--xdlPaletteLime04)';
export const xdlPaletteLime05 = 'var(--xdlPaletteLime05)';
export const xdlPaletteLime06 = 'var(--xdlPaletteLime06)';
export const xdlPaletteLime07 = 'var(--xdlPaletteLime07)';
export const xdlPaletteLime08 = 'var(--xdlPaletteLime08)';
export const xdlPaletteLime09 = 'var(--xdlPaletteLime09)';
export const xdlPaletteLime10 = 'var(--xdlPaletteLime10)';
export const xdlPaletteLime11 = 'var(--xdlPaletteLime11)';
export const xdlPaletteMint01 = 'var(--xdlPaletteMint01)';
export const xdlPaletteMint02 = 'var(--xdlPaletteMint02)';
export const xdlPaletteMint03 = 'var(--xdlPaletteMint03)';
export const xdlPaletteMint04 = 'var(--xdlPaletteMint04)';
export const xdlPaletteMint05 = 'var(--xdlPaletteMint05)';
export const xdlPaletteMint06 = 'var(--xdlPaletteMint06)';
export const xdlPaletteMint07 = 'var(--xdlPaletteMint07)';
export const xdlPaletteMint08 = 'var(--xdlPaletteMint08)';
export const xdlPaletteMint09 = 'var(--xdlPaletteMint09)';
export const xdlPaletteMint10 = 'var(--xdlPaletteMint10)';
export const xdlPaletteMint11 = 'var(--xdlPaletteMint11)';
export const xdlPaletteOrange01 = 'var(--xdlPaletteOrange01)';
export const xdlPaletteOrange02 = 'var(--xdlPaletteOrange02)';
export const xdlPaletteOrange03 = 'var(--xdlPaletteOrange03)';
export const xdlPaletteOrange04 = 'var(--xdlPaletteOrange04)';
export const xdlPaletteOrange05 = 'var(--xdlPaletteOrange05)';
export const xdlPaletteOrange06 = 'var(--xdlPaletteOrange06)';
export const xdlPaletteOrange07 = 'var(--xdlPaletteOrange07)';
export const xdlPaletteOrange08 = 'var(--xdlPaletteOrange08)';
export const xdlPaletteOrange09 = 'var(--xdlPaletteOrange09)';
export const xdlPaletteOrange10 = 'var(--xdlPaletteOrange10)';
export const xdlPaletteOrange11 = 'var(--xdlPaletteOrange11)';
export const xdlPalettePetrol01 = 'var(--xdlPalettePetrol01)';
export const xdlPalettePetrol02 = 'var(--xdlPalettePetrol02)';
export const xdlPalettePetrol03 = 'var(--xdlPalettePetrol03)';
export const xdlPalettePetrol04 = 'var(--xdlPalettePetrol04)';
export const xdlPalettePetrol05 = 'var(--xdlPalettePetrol05)';
export const xdlPalettePetrol06 = 'var(--xdlPalettePetrol06)';
export const xdlPalettePetrol07 = 'var(--xdlPalettePetrol07)';
export const xdlPalettePetrol08 = 'var(--xdlPalettePetrol08)';
export const xdlPalettePetrol09 = 'var(--xdlPalettePetrol09)';
export const xdlPalettePetrol10 = 'var(--xdlPalettePetrol10)';
export const xdlPalettePetrol11 = 'var(--xdlPalettePetrol11)';
export const xdlPalettePurpleRain01 = 'var(--xdlPalettePurpleRain01)';
export const xdlPalettePurpleRain02 = 'var(--xdlPalettePurpleRain02)';
export const xdlPalettePurpleRain03 = 'var(--xdlPalettePurpleRain03)';
export const xdlPalettePurpleRain04 = 'var(--xdlPalettePurpleRain04)';
export const xdlPalettePurpleRain05 = 'var(--xdlPalettePurpleRain05)';
export const xdlPalettePurpleRain06 = 'var(--xdlPalettePurpleRain06)';
export const xdlPalettePurpleRain07 = 'var(--xdlPalettePurpleRain07)';
export const xdlPalettePurpleRain08 = 'var(--xdlPalettePurpleRain08)';
export const xdlPalettePurpleRain09 = 'var(--xdlPalettePurpleRain09)';
export const xdlPalettePurpleRain10 = 'var(--xdlPalettePurpleRain10)';
export const xdlPalettePurpleRain11 = 'var(--xdlPalettePurpleRain11)';
export const xdlPalettePurple01 = 'var(--xdlPalettePurple01)';
export const xdlPalettePurple02 = 'var(--xdlPalettePurple02)';
export const xdlPalettePurple03 = 'var(--xdlPalettePurple03)';
export const xdlPalettePurple04 = 'var(--xdlPalettePurple04)';
export const xdlPalettePurple05 = 'var(--xdlPalettePurple05)';
export const xdlPalettePurple06 = 'var(--xdlPalettePurple06)';
export const xdlPalettePurple07 = 'var(--xdlPalettePurple07)';
export const xdlPalettePurple08 = 'var(--xdlPalettePurple08)';
export const xdlPalettePurple09 = 'var(--xdlPalettePurple09)';
export const xdlPalettePurple10 = 'var(--xdlPalettePurple10)';
export const xdlPalettePurple11 = 'var(--xdlPalettePurple11)';
export const xdlPaletteRed01 = 'var(--xdlPaletteRed01)';
export const xdlPaletteRed02 = 'var(--xdlPaletteRed02)';
export const xdlPaletteRed03 = 'var(--xdlPaletteRed03)';
export const xdlPaletteRed04 = 'var(--xdlPaletteRed04)';
export const xdlPaletteRed05 = 'var(--xdlPaletteRed05)';
export const xdlPaletteRed06 = 'var(--xdlPaletteRed06)';
export const xdlPaletteRed07 = 'var(--xdlPaletteRed07)';
export const xdlPaletteRed08 = 'var(--xdlPaletteRed08)';
export const xdlPaletteRed09 = 'var(--xdlPaletteRed09)';
export const xdlPaletteRed10 = 'var(--xdlPaletteRed10)';
export const xdlPaletteRed11 = 'var(--xdlPaletteRed11)';
export const xdlPaletteWhite = 'var(--xdlPaletteWhite)';
export const xdlPaletteYellow01 = 'var(--xdlPaletteYellow01)';
export const xdlPaletteYellow02 = 'var(--xdlPaletteYellow02)';
export const xdlPaletteYellow03 = 'var(--xdlPaletteYellow03)';
export const xdlPaletteYellow04 = 'var(--xdlPaletteYellow04)';
export const xdlPaletteYellow05 = 'var(--xdlPaletteYellow05)';
export const xdlPaletteYellow06 = 'var(--xdlPaletteYellow06)';
export const xdlPaletteYellow07 = 'var(--xdlPaletteYellow07)';
export const xdlPaletteYellow08 = 'var(--xdlPaletteYellow08)';
export const xdlPaletteYellow09 = 'var(--xdlPaletteYellow09)';
export const xdlPaletteYellow10 = 'var(--xdlPaletteYellow10)';
export const xdlPaletteYellow11 = 'var(--xdlPaletteYellow11)';
export const xdlColorIllustrationBackground =
  'var(--xdlColorIllustrationBackground)';
export const xdlColorIllustrationFillTertiaryGrayPlain =
  'var(--xdlColorIllustrationFillTertiaryGrayPlain)';
export const xdlColorIllustrationOnColorPrimary =
  'var(--xdlColorIllustrationOnColorPrimary)';
export const xdlColorIllustrationOnColorSecondary =
  'var(--xdlColorIllustrationOnColorSecondary)';
export const xdlColorIllustrationOutlinePrimary =
  'var(--xdlColorIllustrationOutlinePrimary)';
export const xdlColorIllustrationOutlineSecondary =
  'var(--xdlColorIllustrationOutlineSecondary)';
